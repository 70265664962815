import { ColumnsType } from 'antd/es/table';
import { useMemo } from 'react';

import { TableCellWrap } from '~/components/TableCellWrap';
import { formatDateTime } from '~/helpers/date';

import { Actions } from '../Actions';

const ImportState = {
  COMPLETED: 'Completed',
  CREATED: 'In Progress',
  FAILED: 'Failed',
  IN_PROGRESS: 'In Progress'
};

export function useImportTableColumns() {
  return useMemo(
    () =>
      [
        {
          title: 'Creation date',
          dataIndex: 'createdOn',
          render: (createdOn) => (
            <TableCellWrap
              value={formatDateTime(createdOn)}
              qaSelector="createdOn"
            />
          )
        },
        {
          title: 'Created By',
          dataIndex: 'createdBy',
          render: (data) => {
            const { firstName, lastName, id } = data || {};
            const value = firstName && lastName && (
              <a href={`/admin/users/${id}`}>
                {firstName} {lastName}
              </a>
            );
            return <TableCellWrap value={value} qaSelector="name" />;
          }
        },
        {
          title: 'Status',
          dataIndex: 'state',
          render: (status) => (
            <TableCellWrap
              value={ImportState[status]}
              qaSelector="description"
            />
          )
        },
        {
          title: 'Actions',
          dataIndex: 'resourceLocation',
          render: (_, data) => <Actions data={data} />
        }
      ] as ColumnsType,
    []
  );
}
