import { isEmpty } from 'lodash/fp';
import { useCallback } from 'react';

import { useUploadDocuments } from './useUploadDocuments';
import { useCredentials } from './useCredentials';

interface PayloadArg {
  documents: File[];
}

export function useOfflineLEadsImport() {
  const uploadDocuments = useUploadDocuments();
  const loadCredentials = useCredentials();

  return useCallback(
    async ({ documents = [] }: PayloadArg) => {
      if (!isEmpty(documents)) {
        const credentials = await loadCredentials({
          documents
        });

        await uploadDocuments({
          files: documents,
          credentials
        });
      }
    },
    [uploadDocuments, loadCredentials]
  );
}
