import {
  createFilter,
  createFilterPayload,
  FilterType,
  SortType
} from '@retail/gql-utils';

import { SearchProjection2Input } from '~/apollo/gql-types';

export interface GroupsOverviewParamsModel
  extends Partial<any>,
    Pick<SearchProjection2Input, 'page' | 'pageSize'> {
  totalPages?: number;
}

export const DEFAULT_SORTING = {
  direction: SortType.ASC,
  property: 'createdOn'
};
const SORTS = [DEFAULT_SORTING];

const OFFLINE_LEADS_TYPE = 'RETAIL_OFFLINE_LEADS_NEWSLETTER_SUBSCRIPTION';

export function getImportsParams({
  page: rawPage,
  pageSize
}: GroupsOverviewParamsModel) {
  const page = Number(rawPage) - 1 || 0;

  const filter = createFilter(
    'dataImportType',
    FilterType.EQUAL,
    OFFLINE_LEADS_TYPE
  );

  return createFilterPayload({
    filter,
    sorts: SORTS,
    page,
    pageSize
  });
}
