import { Modal, Button } from 'antd';
import { makeVar, useReactiveVar } from '@apollo/client';
import { memo, SyntheticEvent, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useOfflineLEadsImport } from '../hooks/useOfflineLeadsImport';

import { Form } from './form';

const modalVars = makeVar(false);

export const hideModal = () => modalVars(false);
export const showModal = () => modalVars(true);

export const UploadModal = memo(() => {
  const visible = useReactiveVar(modalVars);
  const uploadDocuments = useOfflineLEadsImport();

  const form = useForm({
    defaultValues: {},
    mode: 'all'
  });
  const {
    control,
    formState: { isSubmitting, errors, isDirty, isValid },
    handleSubmit,
    reset
  } = form;

  useEffect(() => {
    if (visible) {
      reset({});
    }
  }, [visible, reset]);

  const disabled = !isDirty || !isValid;

  async function onHandleSubmit(event: SyntheticEvent) {
    await handleSubmit(uploadDocuments)();
    hideModal();
  }

  return (
    <Modal
      centered
      visible={visible}
      width={600}
      onCancel={hideModal}
      title="Upload CSV"
      footer={
        <>
          <Button type="default" onClick={hideModal}>
            <span data-qa-selector="cancel-csv-upload">Cancel</span>
          </Button>
          <Button
            type="primary"
            loading={isSubmitting}
            disabled={disabled}
            onClick={onHandleSubmit}
          >
            <span data-qa-selector="upload-csv">Save</span>
          </Button>
        </>
      }
    >
      <FormProvider {...form}>
        <form onSubmit={onHandleSubmit}>
          <Form control={control} errors={errors} />
        </form>
      </FormProvider>
    </Modal>
  );
});
