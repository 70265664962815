import { useCallback } from 'react';

import { DOMAINS } from '~/constants/permissions';
import { GQL_CLIENT } from '~/apollo/gql-client';
import { PresignedUrlsDocument } from '~/apollo/gql-types';

interface PayloadArg {
  fileName: string;
}

export function useDownloadDocument() {
  return useCallback(async ({ fileName }: PayloadArg) => {
    const { data: credentialsData } = await GQL_CLIENT.query({
      query: PresignedUrlsDocument,
      context: {
        headers: {
          'access-domain': DOMAINS.MARKETING_MANAGEMENT
        }
      },
      variables: {
        entities: [{ fileName, httpMethod: 'GET' }]
      }
    });

    return credentialsData?.data?.[0].presignedUrl;
  }, []);
}
