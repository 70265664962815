import { useCallback } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { sumBy } from 'lodash/fp';

import { DropzoneControlled } from '~/components/Form/dropzone';

export const DOCUMENT_NAME = 'documents';
const maxFileSizeMB = 1;

interface Props {
  control: Control;
  errors: any;
}

export function Form({ control, errors }: Props) {
  const [documents] = useWatch({
    control,
    name: [DOCUMENT_NAME]
  });

  const validateFilesSize = useCallback(
    (files) => {
      const newFilesSizeKB = sumBy('size', files) / 1024;
      const totalFileSizeMB =
        (newFilesSizeKB + sumBy('fileSizeKb')(documents?.backOffice)) / 1024;
      return totalFileSizeMB <= maxFileSizeMB;
    },
    [documents?.backOffice]
  );

  return (
    <DropzoneControlled
      errors={errors}
      maxSizeMB={maxFileSizeMB}
      qaSelector="documentUploadDropzone"
      label=""
      multiple={false}
      placeholder="Click or drag file to this area to upload"
      hint="Max 1MB"
      error="Max 1MB"
      accept=".csv"
      controllerProps={{
        name: DOCUMENT_NAME,
        control,
        rules: {
          validate: validateFilesSize
        }
      }}
    />
  );
}
