import { Col, Pagination, PaginationProps, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { DEFAULT_PAGE_OPTIONS } from '~/constants/common';

export interface IPaginationRowProps extends PaginationProps {
  showTotalResults?: boolean;
  isLoading?: boolean;
}

export const PaginationRow = ({
  showTotalResults,
  total,
  isLoading,
  showSizeChanger = false,
  ...props
}: IPaginationRowProps) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col flex="auto">
        {showTotalResults ? (
          <h3 data-qa-selector-name="totalResults">{`${total || 0} ${t(
            'bo.profitAndLoss.pagination.totalResults'
          )}`}</h3>
        ) : null}
      </Col>
      <Col>
        <Pagination
          total={total || 0}
          showSizeChanger={showSizeChanger}
          disabled={isLoading}
          pageSizeOptions={DEFAULT_PAGE_OPTIONS}
          {...props}
        />
      </Col>
    </Row>
  );
};
