import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { Layout, LayoutHeader, LayoutBody } from '~/components/Layout';
import {
  withPermissions,
  PermissionChecker
} from '~/components/PermissionChecker';
import { DOMAINS, PERMISSIONS } from '~/constants/permissions';

import { UploadModal, showModal } from './UploadModal';
import { ImportsTable } from './ImportsTable';

export const OfflineLeads = withPermissions(
  PERMISSIONS.MUTATION_IMPORT_OFFLINE_LEADS,
  DOMAINS.MARKETING_MANAGEMENT
)(() => {
  return (
    <Layout>
      <LayoutHeader title="Offline Leads">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => showModal()}
          data-qa-selector="add_csv"
        >
          Upload CSV
        </Button>
      </LayoutHeader>
      <PermissionChecker
        domain={DOMAINS.MARKETING_MANAGEMENT}
        allow={PERMISSIONS.QUERY_SEARCH_RETAIL_DATA_IMPORT}
      >
        <LayoutBody>
          <ImportsTable />
        </LayoutBody>
      </PermissionChecker>
      <UploadModal />
    </Layout>
  );
});
