import { Space, Table } from 'antd';
import { useSearchParamsController } from '@retail/hooks';
import { MARKETING_OFFLINE_LEAD } from 'routes';

import { useSearchRetailDataImportQuery } from '~/apollo/gql-types';
import { PaginationRow } from '~/components/PaginationRow';
import { DOMAINS } from '~/constants/permissions';
import { REQUEST_BASE_OPTIONS } from '~/constants/api';

import { useImportTableColumns } from './hooks/useImportTableColumns';
import { getImportsParams } from './util';

export const ImportsTable = () => {
  const columns = useImportTableColumns();

  const { controller, onPageChange, updateController } =
    useSearchParamsController(({ queryString, language, navigate }) =>
      navigate(`${MARKETING_OFFLINE_LEAD.LINK({ language })}${queryString}`)
    );

  const { data, loading: isLoading } = useSearchRetailDataImportQuery({
    ...REQUEST_BASE_OPTIONS,
    context: {
      headers: { 'access-domain': DOMAINS.MARKETING_MANAGEMENT }
    },
    variables: {
      search: getImportsParams(controller)
    },
    onCompleted: (data) =>
      updateController({
        totalPages: data?.data?.totalEntityCount
      })
  });
  const dataSource = data?.data?.entities ?? [];

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <PaginationRow
        showTotalResults
        isLoading={isLoading}
        onChange={onPageChange}
        current={controller.page}
        total={controller.totalPages}
        pageSize={controller.pageSize}
      />
      <Table
        rowKey="id"
        dataSource={dataSource}
        columns={columns}
        loading={isLoading}
        pagination={false}
      />
      <PaginationRow
        isLoading={isLoading}
        onChange={onPageChange}
        current={controller.page}
        total={controller.totalPages}
        pageSize={controller.pageSize}
      />
    </Space>
  );
};
