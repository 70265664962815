import cns from 'classnames';
import { notification, Typography } from 'antd';
import React, { memo, useCallback } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { useDropzone, ErrorCode, DropzoneOptions } from 'react-dropzone';
import { concat, isEmpty, pullAt, get } from 'lodash/fp';
import {
  FieldErrors,
  FieldValues,
  useController,
  UseControllerProps
} from 'react-hook-form';

import cn from './styles.less';
import { FileList } from './file-list';

const { Text } = Typography;

type DropzoneControlledProps<T extends FieldValues> = {
  error: string;
  errors: FieldErrors;
  hint: string;
  label: string;
  maxSizeMB: number;
  disabled?: boolean;
  qaSelector: string;
  placeholder: string;
  controllerProps: UseControllerProps<T>;
  accept?: DropzoneOptions['accept'];
  multiple?: boolean;
};

export const DropzoneControlled = memo<DropzoneControlledProps<FieldValues>>(
  ({
    error,
    errors,
    disabled,
    hint,
    label,
    placeholder,
    maxSizeMB,
    qaSelector,
    controllerProps,
    multiple = true,
    accept
  }) => {
    const { field } = useController(controllerProps);

    const onDrop = useCallback(
      async (accepted, rejected) => {
        if (!isEmpty(rejected)) {
          notification.warning({
            message: 'Error',
            description: rejected
              .map(({ file, errors }) =>
                errors
                  ?.map(({ code, message }) =>
                    code === ErrorCode.FileInvalidType
                      ? `Invalid file type: ${file?.name} ${file?.type}`
                      : message
                  )
                  .join(', ')
              )
              .join(', ')
          });
        } else {
          if (multiple) {
            field.onChange(concat(accepted, field.value || []));
          } else {
            field.onChange(accepted);
          }
        }
      },
      [field, multiple]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      disabled,
      maxSize: 1024 * 1024 * maxSizeMB,
      accept,
      multiple
    });

    const onRemove = useCallback(
      (item, index) => {
        field.onChange(pullAt(index)(field.value));
      },
      [field]
    );

    const isError = get(controllerProps?.name)(errors);

    return (
      <>
        <div>
          <label className={cn.label} htmlFor="fileUpload">
            {label}
          </label>
          <div
            {...getRootProps()}
            data-qa-selector={qaSelector}
            className={cns(cn.dropzone, { active: isDragActive })}
          >
            <input {...getInputProps()} id="fileUpload" />
            <InboxOutlined style={{ fontSize: 42, color: '#6EB5F2' }} />
            <p className={cn.placeholder}>{placeholder}</p>
            <span className={cn.hint}>{hint}</span>
          </div>
          <div>{isError && <Text type="danger">{error}</Text>}</div>
        </div>
        {!isEmpty(field?.value) && (
          <FileList list={field.value} handleRemove={onRemove} />
        )}
      </>
    );
  }
);
