import { notification } from 'antd';
import axios from 'axios';
import { useCallback } from 'react';
import { size } from 'lodash/fp';

import { DOMAINS } from '~/constants/permissions';
import { useOfflineLeadsNewsletterSubscriptionsImportMutation } from '~/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '~/constants/api';

const FILE_UPLOADING_TIMEOUT = 1800000;

interface PayloadArg {
  files: File[];
  credentials: unknown;
}

export function useUploadDocuments() {
  const [createDocument] = useOfflineLeadsNewsletterSubscriptionsImportMutation(
    {
      ...REQUEST_BASE_OPTIONS,
      context: {
        headers: {
          'access-domain': DOMAINS.MARKETING_MANAGEMENT
        }
      }
    }
  );

  return useCallback(
    async ({ files, credentials }: PayloadArg) => {
      for (let i = 0; i < size(files); i++) {
        const { presignedUrl } = credentials[i];
        const file = files[i];

        try {
          await axios({
            url: presignedUrl,
            timeout: FILE_UPLOADING_TIMEOUT,
            method: 'PUT',
            data: file
          });

          await createDocument({
            variables: {
              entities: {
                fileName: file.name
              }
            }
          });

          notification.success({
            message: 'Success',
            description: 'Successfully uploaded file'
          });
        } catch ({ message }) {
          notification.warning({
            message: 'Error',
            description: message
          });
        }
      }
    },
    [createDocument]
  );
}
