import { useCallback } from 'react';
import { get } from 'lodash/fp';

import { DOMAINS } from '~/constants/permissions';
import { GQL_CLIENT } from '~/apollo/gql-client';
import { PresignedUrlsDocument } from '~/apollo/gql-types';

interface PayloadArg {
  documents: File[];
}

export function useCredentials() {
  return useCallback(async ({ documents }: PayloadArg) => {
    const payload = documents.map((file) => ({
      fileName: file.name,
      httpMethod: 'PUT'
    }));
    const { data: credentialsData } = await GQL_CLIENT.query({
      query: PresignedUrlsDocument,
      context: {
        headers: {
          'access-domain': DOMAINS.MARKETING_MANAGEMENT
        }
      },
      variables: {
        entities: payload
      }
    });

    return get(['data'], credentialsData);
  }, []);
}
