import { PathRouteProps } from 'react-router-dom';

import { OfflineLeads } from './pages/OfflineLeads';

export const ROOT = {
  ROUTE: '/:language',
  LINK: ({ language }) => `/${language}`
};

const MARKETING = {
  ROUTE: `/marketing`,
  ROUTE_RELATIVE: `${ROOT.ROUTE}/marketing`,
  LINK: ({ language }) => `${ROOT.LINK({ language })}/marketing`
};

export const MARKETING_OFFLINE_LEAD = {
  ROUTE: '/offline-leads',
  ROUTE_RELATIVE: `${MARKETING.ROUTE_RELATIVE}/offline-leads`,
  LINK: ({ language }) => `${MARKETING.LINK({ language })}/offline-leads`
};

export const ROUTES: PathRouteProps[] = [
  {
    path: MARKETING_OFFLINE_LEAD.ROUTE_RELATIVE,
    element: <OfflineLeads />
  }
];
