import React, { PropsWithChildren, ReactNode } from 'react';
import ReactHelmet from 'react-helmet';
import cns from 'classnames';

import cn from './styles.less';

export interface IHeaderProps
  extends PropsWithChildren<Record<string, unknown>> {
  title?: ReactNode | string;
  helmetTitle?: string;
  icon?: ReactNode;
  className?: string;
}

export const Header = ({
  title,
  helmetTitle,
  icon,
  children,
  className
}: IHeaderProps) => {
  return (
    <div className={cns(cn.header, className)}>
      <ReactHelmet title={helmetTitle || title} />

      <h2 className={cn.titleWrapper}>
        {icon && <span className={cn.iconWrapper}>{icon}</span>}

        <span className={cn.title}>{title}</span>
      </h2>

      {children}
    </div>
  );
};
