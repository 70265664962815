import React, { useState, useCallback } from 'react';
import { Button, Tooltip } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';

import { useDownloadDocument } from '../hooks/useDownloadDocument';

export const Actions = ({ data }) => {
  const getDownloadUrl = useDownloadDocument();
  const [isLoading, setIsLoading] = useState(false);

  const handleDownload = useCallback(
    async (fileName) => {
      setIsLoading(true);

      const url = await getDownloadUrl({ fileName });

      setIsLoading(false);
      if (url) {
        global.open(url, '_blank');
      }
    },
    [getDownloadUrl]
  );

  return (
    <div>
      {data.resourceLocation && (
        <Tooltip title="Download original file">
          <Button
            type="primary"
            onClick={() => handleDownload(data.resourceLocation)}
            icon={<CloudDownloadOutlined />}
            data-qa-selector="download_file"
            loading={isLoading}
          />
        </Tooltip>
      )}
      {data.errorsResourceLocation && (
        <Tooltip title="Download errors file">
          <Button
            type="primary"
            onClick={() => handleDownload(data.errorsResourceLocation)}
            danger
            style={{ marginLeft: '10px' }}
            icon={<CloudDownloadOutlined />}
            data-qa-selector="download_error_file"
            loading={isLoading}
          />
        </Tooltip>
      )}
    </div>
  );
};
